import React from 'react'
import { IndexSectionProps } from 'Views/index/IndexPage'
import CountTexts from 'Components/CountTexts/CountTexts'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Headings from 'Components/Headings/Headings'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import { ANIM_DELAY, fadeUp } from 'Utils/animations'
import styles from './SectionServices.module.scss'

const SectionServices = (props: IndexSectionProps) => {
  const { data } = props

  return (
    <BaseContainer Component="section">
      <Headings data={data.services_caption} />
      <Row className={styles.items} vertical="top" horizontal="spaced">
        {data.services.map((service, index) => {
          const startIndex = index + 2 /* 2 headings */
          return (
            <div key={service.id} className={styles.item}>
              <Text
                className={styles.itemHeading}
                {...fadeUp({ delay: startIndex * ANIM_DELAY })}
              >
                {service.heading}
              </Text>
              <Text
                className={styles.itemDescription}
                variant="sm"
                {...fadeUp({ delay: startIndex * ANIM_DELAY + 100 })}
              >
                {service.description}
              </Text>
              {service.count && (
                <CountTexts
                  className={styles.itemCount}
                  count={service.count}
                  heading={service.count_label}
                  {...fadeUp({ delay: startIndex * ANIM_DELAY + 200 })}
                />
              )}
            </div>
          )
        })}
      </Row>
    </BaseContainer>
  )
}

export default SectionServices
