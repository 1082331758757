import React, { FunctionComponent, SVGProps, useEffect, useRef } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { IndexSectionProps } from 'Views/index/IndexPage'
import { useI18next } from 'gatsby-plugin-react-i18next'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Row from 'Components/Row/Row'
import Text from 'Components/texts/Text'
import BackTextRU from 'Assets/back-text-ru.inline.svg'
import BackTextEN from 'Assets/back-text-en.inline.svg'
import IconPen from 'Assets/icon-pen.inline.svg'
import Cogs1 from 'Assets/cogs-1.inline.png'
import Cogs2 from 'Assets/cogs-2.inline.png'
import Cogs3 from 'Assets/cogs-3.inline.png'
import { fade, fadeUp } from 'Utils/animations'
import { parallax3D } from 'Utils/parallax'
import styles from './SectionMain.module.scss'

export type ServiceTextType = Array<{
  title: string
  sub: string
}>

export type SvgType = FunctionComponent<
  SVGProps<SVGSVGElement & { className?: string | undefined }>
>

const BACK_TEXT_BY_LOCALE: Record<string, SvgType> = {
  ru: BackTextRU,
  en: BackTextEN,
}

const SectionMain = (props: IndexSectionProps) => {
  const root = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)
  const isInit = useRef(false)
  const isLaptop = useMediaQuery('(min-width:1000px)', { defaultMatches: false })

  const { t, i18n } = useI18next()

  useEffect(() => {
    if (!isInit.current && isLaptop && content.current && root.current) {
      isInit.current = true
      parallax3D(root.current, content.current)
    }
  }, [isLaptop])

  const _services = t('services', { returnObjects: true }) as ServiceTextType
  const services = Array.isArray(_services) ? _services : []

  const BackText = BACK_TEXT_BY_LOCALE[i18n.resolvedLanguage ?? ''] ?? BackTextRU

  // <div className={styles.man} data-depth="0.2">
  //   <img src={Man} alt="" />
  // </div>
  return (
    <BaseContainer
      ref={root}
      contentRef={content}
      className={styles.root}
      contentClassName={styles.content}
      Component='section'
    >
      <div className={styles.backTextContainer}>
        <BackText className={styles.backText} {...fade({ duration: 1000 })} />
        <div className={styles.dividerContainer}>
          <div className={styles.divider}>
            <IconPen className={styles.iconPen} />
          </div>
        </div>
      </div>
      <Row className={styles.services}>
        {services.map((service, index) => (
          <div key={index} className={styles.service} {...fadeUp({ index: index + 1 })}>
            <Text>{service.title}</Text>
            <Text variant='sm'>{service.sub}</Text>
          </div>
        ))}
      </Row>
      <img className={styles.cogs1} src={Cogs1} alt='' />
      <img className={styles.cogs2} src={Cogs2} alt='' />
      <img className={styles.cogs3} src={Cogs3} alt='' />
    </BaseContainer>
  )
}

export default SectionMain
