import React, { ElementType, useCallback } from 'react'
import classNames from 'classnames'
import { ProjectBaseFragment } from 'Typings/generated-gatsby-types'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Button from 'Components/touchables/Button'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import { fade, fadeDirection, FadeDirection } from 'Utils/animations'
import SectionIndex from 'Components/SectionIndex/SectionIndex'
import Touchable from 'Components/touchables/Touchable'
import { localImageUrl } from 'Utils/api'
import styles from './ProjectPreview.module.scss'

export type ProjectPreviewProps = {
  className?: string
  project: ProjectBaseFragment
  link?: string
  linkText?: string
  index?: number
  totalCount?: number
  showTexts?: boolean
  textsAnimations?: FadeDirection
  Component?: ElementType
}

const ProjectPreview = (props: ProjectPreviewProps) => {
  const {
    className = '',
    project,
    link = '',
    linkText = '',
    index,
    totalCount,
    showTexts,
    textsAnimations = 'up',
    Component = 'div',
  } = props

  const anim = useCallback((params?) => fadeDirection(textsAnimations, params), [
    textsAnimations,
  ])

  return (
    <Component className={classNames([styles.root, className])}>
      <Touchable
        className={styles.touchable}
        disabled={!link}
        link={link}
        styling="clear"
      >
        <img className={styles.image} src={localImageUrl(project.preview)} alt="" />
        {project.show_overlay && <div className={styles.overlay} />}
        <BaseContainer
          className={styles.content}
          contentClassName={styles.contentInner}
          vertical="sm"
        >
          {(typeof showTexts !== 'boolean' || showTexts) && (
            <>
              <Text className={styles.workType} variant="sm-bold" {...fade({ index: 0 })}>
                {project.work_type}
              </Text>
              <div className={styles.centerContent}>
                <Text className={styles.name} variant="heading" {...anim({ index: 0 })}>
                  {project.name}
                </Text>
                <Text className={styles.appInfo} variant="sm" {...anim({ index: 1 })}>
                  {project.app_type}
                  <span className={styles.appInfoDivider}>/</span>
                  {project.years}
                </Text>
                <Text className={styles.shortInfo} variant="sm" {...anim({ index: 2 })}>
                  {project.short_info}
                </Text>
              </div>
            </>
          )}
          <Row className={styles.footer} {...anim()}>
            {link && linkText && (
              <Button
                className={styles.footerLink}
                title={linkText}
                variant="flat"
                theme="alt"
              />
            )}
            {totalCount && index && (
              <SectionIndex
                className={styles.footerIndex}
                index={index}
                totalCount={totalCount}
              />
            )}
          </Row>
        </BaseContainer>
      </Touchable>
    </Component>
  )
}

export default ProjectPreview
