import React from 'react'
import { IndexSectionProps } from 'Views/index/IndexPage'
import ProjectsSlider from 'Components/ProjectsSlider/ProjectsSlider'

const SectionProjects = (props: IndexSectionProps) => {
  const { data } = props

  return <ProjectsSlider projects={data.projects} />
}

export default SectionProjects
