import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Swiper as SwiperClass } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ProjectBaseFragment } from 'Typings/generated-gatsby-types'
import { usePageScrollPosition, useSliderProgress, viewport } from 'Utils/common'
import { fadeUp } from 'Utils/animations'
import { PATHS, ROUTES } from 'Utils/routing'
import ProjectPreview from 'Components/ProjectPreview/ProjectPreview'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import SliderControls from 'Components/slider/SliderControls'
import SectionIndex from 'Components/SectionIndex/SectionIndex'
import Button from 'Components/touchables/Button'
import Row from 'Components/Row/Row'
import styles from './ProjectsSlider.module.scss'

export type ProjectsSliderProps = {
  className?: string
  projects: Array<{ project: ProjectBaseFragment }>
}

const ProjectsSlider = (props: ProjectsSliderProps) => {
  const { projects: _projects, className } = props
  const [hideHeader, setHideHeader] = useState(false)
  const [allowProcessScroll, setAllowProcessScroll] = useState(false)
  const root = useRef<HTMLElement>(null)
  const prevSlide = useRef(0)
  const prevIndex = prevSlide.current
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const projects = _projects.filter(Boolean)

  const { slideIndex, onSlideChange } = useSliderProgress({
    onSlideChange: () => {
      setHideHeader(true)
      setTimeout(() => setAllowProcessScroll(true), 700)
      if (root.current) {
        const { height } = viewport()
        // Для экранов высотой меньших блока не нужно делать скролл,
        // т.к. контролы скрываются за границами экрана и это неудобно
        if (root.current.offsetHeight <= height) {
          root.current.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
  })

  const totalCount = projects.length

  useEffect(() => {
    prevSlide.current = slideIndex
  }, [slideIndex])

  usePageScrollPosition(() => {
    if (allowProcessScroll) {
      setHideHeader(false)
      setAllowProcessScroll(false)
    }
  }, [allowProcessScroll])

  return (
    <section ref={root} className={className}>
      {hideHeader && (
        <Helmet>
          <html data-hide-header={true} />
        </Helmet>
      )}
      <Swiper
        onSwiper={setSwiper}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={onSlideChange}
      >
        {projects.map((i, index) => (
          <SwiperSlide key={i.project.id}>
            <ProjectPreview
              project={i.project}
              showTexts={slideIndex === index}
              link={ROUTES.project(i.project.slug)}
              textsAnimations={
                slideIndex === prevIndex
                  ? 'up'
                  : slideIndex > prevIndex
                  ? 'left'
                  : 'right'
              }
            />
          </SwiperSlide>
        ))}
        <Controls swiper={swiper} totalCount={totalCount} slideIndex={slideIndex} />
      </Swiper>
    </section>
  )
}

function Controls({
  swiper,
  slideIndex,
  totalCount,
}: {
  swiper: SwiperClass | null
  slideIndex: number
  totalCount: number
}) {
  const { t } = useTranslation()

  return (
    <BaseContainer className={styles.controls} vertical="sm">
      <Row className={styles.controlsInner}>
        <SliderControls
          className={styles.buttons}
          swiper={swiper}
          slideIndex={slideIndex}
          totalCount={totalCount}
          theme="alt"
          {...fadeUp()}
        />
        <div className={styles.margin} />
        <Button
          className={styles.link}
          link={PATHS.portfolio}
          title={t('all_projects')}
          variant="flat"
          theme="alt"
          {...fadeUp({ index: 1 })}
        />
        <SectionIndex
          className={styles.index}
          index={slideIndex + 1}
          totalCount={totalCount}
          {...fadeUp({ index: 2 })}
        />
      </Row>
    </BaseContainer>
  )
}

export default ProjectsSlider
