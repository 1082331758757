import React from 'react'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import { fadeUp } from 'Utils/animations'
import styles from './Headings.module.scss'

export type HeadingsProps = {
  className?: string
  data: {
    heading?: string
    description?: string
  }
}

const Headings = (props: HeadingsProps) => {
  const {
    className = '',
    data: { heading, description },
  } = props

  return (
    <div className={classNames([styles.root, className])}>
      <Text className={styles.heading} Component="h2" variant="heading" {...fadeUp()}>
        {heading || ''}
      </Text>
      {description && (
        <Text className={styles.description} variant="sm" {...fadeUp({ index: 1 })}>
          {description}
        </Text>
      )}
    </div>
  )
}

export default Headings
