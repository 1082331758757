import React from 'react'
import classNames from 'classnames'
import Row from 'Components/Row/Row'
import Text from 'Components/texts/Text'
import { padStart } from 'lodash'
import styles from './SectionIndex.module.scss'

export type SectionIndexProps = {
  className?: string
  index: number
  totalCount: number
}

const SectionIndex = (props: SectionIndexProps) => {
  const { className = '', index, totalCount, ...other } = props

  return (
    <Row className={classNames([styles.root, className])} {...other}>
      <Text className={styles.index} variant="sm">
        {totalCount}
      </Text>
      <Text className={styles.divider} variant="sm">
        {'|'}
      </Text>
      <Text className={styles.totalCount}>{padStart(index.toString(), 2, '0')}</Text>
    </Row>
  )
}

export default SectionIndex
