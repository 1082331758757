import React from 'react'
import IndexPage from 'Views/index/IndexPage'
import { graphql, PageProps } from 'gatsby'
import { HomepageQuery } from 'Typings/generated-gatsby-types'

const Index = (props: PageProps<HomepageQuery>) => {
  return <IndexPage data={props.data?.strapiHomepage} />
}

export const query = graphql`
  fragment HomepageData on StrapiHomepage {
    seo {
      meta_description
      meta_title
      meta_image {
        localFile {
          publicURL
        }
      }
    }
    services_caption {
      heading
      description
    }
    services {
      id
      heading
      description
      count
      count_label
    }
    dev_section {
      content {
        image {
          localFile {
            publicURL
          }
        }
        text
        description
      }
      background {
        localFile {
          publicURL
        }
      }
      illustration_type
    }
    about_caption {
      heading
      description
    }
    about_text
    about_counts {
      count
      heading
      description
    }
    projects {
      project {
        id
        name
        short_info
        slug
        years
        work_type
        app_type
        show_overlay
        preview {
          localFile {
            publicURL
          }
        }
        views {
          localFile {
            publicURL
          }
        }
      }
    }
  }

  query($language: String!) {
    strapiHomepage(locale: { eq: $language }) {
      ...HomepageData
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Index
