import React from 'react'
import { IndexSectionProps } from 'Views/index/IndexPage'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Headings from 'Components/Headings/Headings'
import Row from 'Components/Row/Row'
import Text from 'Components/texts/Text'
import CountTexts from 'Components/CountTexts/CountTexts'
import { fadeUp } from 'Utils/animations'
import styles from './SectionAbout.module.scss'

const SectionAbout = (props: IndexSectionProps) => {
  const { data } = props

  return (
    <BaseContainer className={styles.root} Component="section">
      <Headings data={data.about_caption} />
      <Row className={styles.texts} vertical="top">
        <Text className={styles.text} {...fadeUp({ index: 0 })}>
          {data.about_text}
        </Text>
        <Row className={styles.infos} vertical="top" horizontal="spaced">
          {data.about_counts.map((info, index) => (
            <div key={index} className={styles.infoItem}>
              <CountTexts
                className={styles.info}
                {...info}
                {...fadeUp({ index: index + 1 })}
              />
            </div>
          ))}
        </Row>
      </Row>
    </BaseContainer>
  )
}

export default SectionAbout
