import React from 'react'
import DevSection from 'Components/sections/DevSection'
import { IndexSectionProps } from 'Views/index/IndexPage'

const SectionDev = (props: IndexSectionProps) => {
  const { data } = props

  return <DevSection data={data.dev_section} />
}

export default SectionDev
